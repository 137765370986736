import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import logoImageSrc from "images/barber-shop-logo.png";

import Header, {
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const NavLink = tw.a`
  cursor-pointer text-sm my-1 lg:text-lg lg:my-0
  font-semibold tracking-wide transition duration-300
   border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
   py-2 px-5
`;

const StyledHeader = styled(Header)`
  ${tw`w-full pt-4 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)``;
const Container = styled.div`
  ${tw`relative h-screen -mx-8 -mt-8 bg-center bg-cover min-h-144`}
  background-image: url("https://images.unsplash.com/photo-1621605815971-fbc98d665033?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`-mt-24 text-2xl font-black leading-snug text-center text-gray-100 lg:text-5xl sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const OpeningTimes = tw.div`sm:text-base lg:text-xl text-white mt-8 lg:mt-16 flex flex-col gap-1 max-w-xs w-full`;
const Time = tw.div`flex justify-between`;

const ContactDiv = tw.div`flex flex-col items-center sm:flex-row sm:gap-4 mt-4 lg:text-base text-gray-300 max-w-lg`;

const logoLink = (
  <LogoLink href="/">
    <img src={logoImageSrc} alt="Logo" />
    Backwell Barbers
  </LogoLink>
);

const PrimaryAction = tw.a`text-center align-middle rounded-lg px-3 lg:px-8 py-3 text-sm sm:text-base sm:mt-4 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const SecondaryAction = tw.a`text-center align-middle rounded-lg px-3 lg:px-8 py-3 text-sm sm:text-base sm:mt-4 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-secondary-500 text-gray-100 hocus:bg-secondary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

const Actions = tw.div`flex gap-6`;

export default () => {
  const navLinks = [
    <NavLinks>
      <NavLink href="#locationSection">Location</NavLink>
      <NavLink href="#pricingSection">Prices</NavLink>
      <NavLink href="#portfolioSection">Portfolio</NavLink>
      {/* <NavLink href="#testamonialsSection" tw="py-2 text-lg mx-5">
        Testamonials
      </NavLink> */}
      <PrimaryLink
        href="https://backwellbarbers.nearcut.com/users/sign_in"
        target="_blank"
      >
        Book Online
      </PrimaryLink>
    </NavLinks>,
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader logoLink={logoLink} links={navLinks} />
        <Content>
          <Heading>Barbers in Backwell, North Somerset</Heading>

          <ContactDiv>
            <NavLink
              href="mailto: appointment.backwellbarbers@gmail.com
            "
            >
              appointment.backwellbarbers@gmail.com
            </NavLink>
          </ContactDiv>

          <Actions>
            <PrimaryAction
              href="https://backwellbarbers.nearcut.com/users/sign_in"
              target="_blank"
            >
              Book Online
            </PrimaryAction>
            <SecondaryAction href="tel: 07946643059">
              07946643059
            </SecondaryAction>
          </Actions>

          <OpeningTimes>
            <Time>
              <span>Monday</span>
              <span>CLOSED</span>
            </Time>
            <Time>
              <span>Tuesday</span>
              <span>9:00am - 6:00pm</span>
            </Time>
            <Time>
              <span>Wednesday</span>
              <span>9:00am - 6:30pm</span>
            </Time>
            <Time>
              <span>Thursday</span>
              <span>9:00am - 6:30pm</span>
            </Time>
            <Time>
              <span>Friday</span>
              <span>9:00am - 6:00pm</span>
            </Time>
            <Time>
              <span>Saturday</span>
              <span>8:00am - 1:30pm</span>
            </Time>
            <Time>
              <span>Sunday</span>
              <span>CLOSED</span>
            </Time>
          </OpeningTimes>
        </Content>
      </HeroContainer>
    </Container>
  );
};
