import React from "react";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";

const Container = tw(ContainerBase)` bg-gray-800 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;

const defaultPrices = [
  {
    title: "Gents Cut",
    cost: "£13",
  },
  {
    title: "Boys Cut",
    cost: "£12",
  },
  {
    title: "OAP",
    cost: "£10",
  },
  {
    title: "Skin Fade",
    cost: "£17",
  },
  {
    title: "Zero Fade",
    cost: "£17",
  },
  {
    title: "Clippers all Over",
    cost: "£10",
  },
  {
    title: "Beard Trim",
    cost: "£7",
  },
  {
    title: "Beard Trim with any cut",
    cost: "£4",
  },
];

const PricesContainer = tw.div`text-base sm:text-xl text-white mt-8 flex flex-col gap-1 max-w-xs w-full mx-auto`;
const Price = tw.div`flex justify-between border-b border-gray-800`;

export default ({ heading = "Prices", prices = defaultPrices }) => {
  return (
    <Container id="pricingSection">
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading>{heading}</Heading>
        </HeadingContainer>
        <PricesContainer>
          {prices.map((price, index) => (
            <Price key={index}>
              <span>{price.title}</span>
              <span tw="font-bold">{price.cost}</span>
            </Price>
          ))}
        </PricesContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
