import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import LocationMap from "components/maps/LocationMap";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-2 lg:py-5`;

const FormContainer = styled.div`
  ${tw`relative p-3 text-gray-100 bg-gray-900 rounded-lg sm:p-4 md:p-10`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl font-bold sm:text-4xl`}
  }
  input,
  textarea {
    ${tw`w-full py-2 text-base font-medium tracking-wide text-gray-100 transition duration-200 bg-transparent border-b-2 hocus:border-pink-400 focus:outline-none`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between flex gap-5 sm:gap-10`;
const Column1 = tw.div`flex flex-col justify-center text-sm sm:text-base`;
const Column2 = tw.div`flex-1`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block w-full px-3 py-2 mt-5 text-sm font-semibold tracking-wider text-center text-gray-100 transition-colors duration-300 rounded bg-primary-500 hover:bg-primary-700 focus:shadow-outline focus:outline-none`}
`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

export default () => {
  return (
    <Container id="locationSection">
      <Content>
        <FormContainer>
          <div tw=" mx-auto max-w-4xl">
            <h2>Location</h2>
            <TwoColumn>
              <Column1>
                <div tw="lg:text-lg pl-1">
                  <div>Backwell Barbers</div>
                  <div>28 W Town Rd</div>
                  <div>Backwell</div>
                  <div>West Town</div>
                  <div>Bristol</div>
                  <div>BS48 3HH</div>
                </div>

                <ActionButton as="a" href="tel:07946643059">
                  Call: 07946643059
                </ActionButton>
              </Column1>
              <Column2>
                <LocationMap />
              </Column2>
            </TwoColumn>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
