import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent";
import Pricing from "components/pricing/Pricing";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import Footer from "components/footers/MiniCenteredFooter";
import SimpleContactUs from "components/forms/SimpleContactUs";
import ImgGallery from "components/image-gallery/ImgGallery";
import tw from "twin.macro";
import styled from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as ArrowUpIcon } from "images/up-arrow.svg";
import { useState, useEffect } from "react";

export default () => {
  const ControlsContainer = tw.div`sm:ml-auto flex fixed right-[3rem] bottom-[3rem]`;
  const ControlButton = styled.button`
    ${tw`p-2 text-gray-600 transition-colors duration-300 rounded bg-primary-100 hover:text-primary-700 focus:outline-none first:ml-0 sm:first:ml-4 sm:mt-0`}
    .icon {
      ${tw`w-6 fill-[#ffffff]`}
    }
  `;

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    console.log(position);
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AnimationRevealPage>
        <Hero />
        <SimpleContactUs />
        <Pricing />
        <ImgGallery />
        <Footer />
      </AnimationRevealPage>
      {scrollPosition >= 600 ? (
        <ControlsContainer>
          <ControlButton
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <ArrowUpIcon className="icon" />
          </ControlButton>
        </ControlsContainer>
      ) : null}
    </>
  );
};
