const LocationMap = () => {
  return (
    <iframe
      title="Location Map"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d739.8174453150649!2d-2.741650414632056!3d51.41335548679671!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd0656357ae85024!2sBackwell%20Barbers!5e0!3m2!1sen!2suk!4v1659879638171!5m2!1sen!2suk"
      allowfullscreen
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
      style={{ width: "100%", height: "300px" }}
    ></iframe>
  );
};

export default LocationMap;
