import ImageGallery from "react-image-gallery";
import { Container } from "components/misc/Layouts.js";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";

import portfolioImage1 from "images/portfolio-1.jpg";
import portfolioImage2 from "images/portfolio-2.jpg";
import portfolioImage3 from "images/portfolio-3.jpg";
import portfolioImage4 from "images/portfolio-4.jpg";
import portfolioImage5 from "images/portfolio-5.jpg";
import portfolioImage6 from "images/portfolio-6.jpg";
import portfolioImage7 from "images/portfolio-7.jpg";

const Heading = tw(SectionHeading)`py-5`;

const images = [
  {
    original: portfolioImage1,
    thumbnail: portfolioImage1,
  },
  {
    original: portfolioImage2,
    thumbnail: portfolioImage2,
  },
  {
    original: portfolioImage3,
    thumbnail: portfolioImage3,
  },
  {
    original: portfolioImage4,
    thumbnail: portfolioImage4,
  },
  {
    original: portfolioImage5,
    thumbnail: portfolioImage5,
  },
  {
    original: portfolioImage6,
    thumbnail: portfolioImage6,
  },
  {
    original: portfolioImage7,
    thumbnail: portfolioImage7,
  },
];

const PrimaryBackgroundContainer = tw(
  Container
)`-mx-8 px-8 bg-gray-900 text-gray-100`;

const GalleryContainer = tw.div`w-full lg:w-5/12 2xl:w-4/12 mx-auto`;

const ImgGallery = () => {
  return (
    <PrimaryBackgroundContainer id="portfolioSection">
      <Heading>Portfolio</Heading>
      <GalleryContainer>
        <ImageGallery items={images} />
      </GalleryContainer>
    </PrimaryBackgroundContainer>
  );
};

export default ImgGallery;
